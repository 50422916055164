import { Injectable } from '@angular/core';
import { shareReplay } from 'rxjs';
import { map } from 'rxjs/operators';
import { buildFilterStr, convertMbToBytes, flipObject, latinizeStr } from '@bazis/utils';
import { IsActiveMatchOptions, Router } from '@angular/router';
import { MaskSettings } from '@bazis/form/models/form-element.types';
import { BazisConfigurationService } from '@bazis/configuration.service';
import * as bazisConfig from '@bazis/configuration.service';

export const API_URL = '/api/web/v1';
export const DEFAULT_LIST_LIMIT = 20;
export const ALL_LIST_LIMIT = 1000;

export const AVAILABLE_LANGUAGES = ['ru', 'en'];
export const SELECTED_LANGUAGE =
    AVAILABLE_LANGUAGES.indexOf(localStorage.getItem('lang')) > -1
        ? localStorage.getItem('lang')
        : 'ru';

export const API_DATETIME_FORMAT = 'YYYY-MM-DD[T]HH:mm:ssZ';
export const API_DATETIME_FORMAT_Z = 'YYYY-MM-DD[T]HH:mm:ss[Z]';
export const VAT = 20;

export const SUCCESS_LOGIN_PAGE = '/success-login';
export const SUCCESS_INVITE_PAGE = '/success-invite';

export const MAX_FILE_SIZE = convertMbToBytes(10); // 10мб

export const FAQ_TG_CHANNEL_LINK = 'https://t.me/Smart_Waste';
export const TG_BOT = '@smartwaste_bot';
export const TG_COMMAND = '/start';

export const DOCUMENT_LINKS = {
    privacyPolicy: '/legal-documents/privacy_policy',
    userAgreement: '/legal-documents/site_rules',
    cookiePolicy: '/legal-documents/cookie_policy',
    agencyContract: '/legal-documents/agency_contract',
    partnerContract: '/legal-documents/partner_contract',
    buyerContract: '/legal-documents/sales_agency_contract',
};

export const UNDERCONSTRUCTION_TITLE = {
    feature: 'title.featureUnderconstruction',
    section: 'title.sectionUnderconstruction',
};

export const OPERATOR_REPORT_SETTINGS = {
    startYear: 2022,
    startMonth: 11,
    filterTimeCorrection: -3,
};

export const ORDER_BID_VALIDATION_PARAMS = {
    maxDiffExportAndDelivery: 7, // (days) Доставка тары не ранее чем за неделю до минимальной даты вывоза отхода (position_item)
    minCarrierSearchDiffWithExecutionDate: 2, // (days) окончание сбора предложений не позднее первой даты исполнения (доставки тары или вывоза отходов) минус 2 дня.
    maxCarrierDiffWithCurrentDate: 10, // (days) период сбора предложений должен быть не дольше 10 дней от текущей даты.
    minCarrierSearchDiffWithCurrentDate: 1, // (hours) Также период сбора предложений должен быть не менее 1 часа.
    allExportLimit: 7, // (days) date_waste_export во всех waste_position_item всей заявки (bid) должны укладываться в 7 дней
};

// configuration is set up in standard angular date pipe format

export const DATETIME_LOCALE = bazisConfig.DATETIME_LOCALE;

export const ROLE = {
    wasteGenerator: 'AIS_OSSIG_MO.OSSIG_MO_LK_OO',
    wasteRecycling: 'AIS_OSSIG_MO.OSSIG_MO_LK_OI',
    wasteTransportation: 'AIS_OSSIG_MO.OSSIG_MO_LK_OT',
    wasteOperator: 'AIS_OSSIG_MO.OSSIG_MO_ADMIN',
    anonymous: 'AIS_OSSIG_MO.OSSIG_PUBLIC_USER',
    base: 'AIS_OSSIG_MO.OSSIG_BASE_USER',
    director: 'AIS_OSSIG_MO.OSSIG_MO_LK_SUPERVISOR',
    driver: 'AIS_OSSIG_MO.OSSIG_MO_LK_DRIVER',
    wasteAcceptor: 'AIS_OSSIG_MO.OSSIG_MO_LK_ACCEPTOR',
    referral: 'AIS_OSSIG_MO.OSSIG_MO_LK_PARTNER',
    generalPartner: 'AIS_OSSIG_MO.OSSIG_MO_LK_GENERAL_PARTNER',
    seller: 'AIS_OSSIG_MO.OSSIG_MO_LK_SELLER',
    buyer: 'AIS_OSSIG_MO.OSSIG_MO_LK_BUYER',
    empty: null,
};

export const ROLE_ACCOUNT_TYPE = {
    [ROLE.referral]: 'partner',
    [ROLE.generalPartner]: 'partner',
    [ROLE.wasteGenerator]: 'customer',
    [ROLE.buyer]: 'customer',
    [ROLE.seller]: 'executor',
    [ROLE.wasteTransportation]: 'executor',
    [ROLE.wasteRecycling]: 'executor',
    [ROLE.wasteOperator]: 'system',
};

export const DISABLE_NOTIFICATIONS_ROLES = [ROLE.anonymous, ROLE.base];

export const COLORS = {
    primary: '#00A2AD',
    secondary: '#43B1F2',
    tertiary: '#817AF9',
    action: '#164982',
    info: '#C4C4C4',
    success: '#78C649',
    warning: '#F6BF36',
    danger: '#ED1A34',
    light: '#f4f5f8',
    medium: '#92949c',
    dark: '#111214',
};

export const AUTH_PAGE_URL = '/login';

export const ALL_SEARCH_MODELS = [
    {
        id: 'all',
        nameKey: 'search.all',
    },
    {
        id: 'long_term.contract',
        nameKey: 'search.contracts',
        urlStart: '/contracts/',
    },
    {
        id: 'long_term.contractorder',
        nameKey: 'search.orders',
        urlStart: '/orders/',
    },
    {
        id: 'fulfilment.carriertask',
        nameKey: 'search.tasks',
        urlStart: '/carrier-tasks/',
    },
    {
        id: 'waste_source.wasteplace',
        nameKey: 'search.wasteplace',
        urlStart: '/wasteplaces/',
    },
    {
        id: 'facility.facility',
        nameKey: 'search.facility',
        urlStart: '/facilities/',
    },
    {
        id: 'carrier.vehicle',
        nameKey: 'search.vehicle',
        urlStart: '/vehicles/',
    },
    {
        id: 'sales.salescontract',
        nameKey: 'search.salesContract',
        urlStart: '/sale-contracts/',
    },
];

export const ROLE_SEARCH_MODEL_IDS = {
    [ROLE.wasteGenerator]: [
        'all',
        'long_term.contract',
        'long_term.contractorder',
        'fulfilment.carriertask',
        'waste_source.wasteplace',
    ],
    [ROLE.wasteRecycling]: [
        'all',
        'long_term.contract',
        'long_term.contractorder',
        'fulfilment.carriertask',
        'facility.facility',
    ],
    [ROLE.wasteTransportation]: [
        'all',
        'long_term.contract',
        'long_term.contractorder',
        'fulfilment.carriertask',
        'carrier.vehicle',
        'sales.salescontract',
    ],
    [ROLE.seller]: ['all', 'sales.salescontract'],
    [ROLE.buyer]: ['all', 'sales.salescontract'],
    [ROLE.wasteOperator]: ALL_SEARCH_MODELS.map((v) => v.id),
    [ROLE.anonymous]: [],
    [ROLE.base]: [],
    [ROLE.director]: [],
    [ROLE.driver]: [],
    [ROLE.wasteAcceptor]: [],
    [ROLE.referral]: [],
    [ROLE.empty]: [],
    [ROLE.generalPartner]: [],
};

export const ROLE_REVERT = flipObject(ROLE);

export const CARRIER_REPORTS_PRIORITY = {
    created: {
        id: 'created',
        titleKey: 'created',
        dispatcherStatus: 'created',
        color: 'secondary',
        reportType: '',
        priority: 0,
    },
    wastesource_arrival_reports: {
        id: 'wastesource_arrival_reports',
        reportType: 'fulfilment.wastesource_arrival_report',
        titleKey: 'arrivedAtWasteSource',
        dispatcherStatus: 'started',
        color: 'primary',
        priority: 1,
    },
    loading_reports: {
        id: 'loading_reports',
        reportType: 'fulfilment.loading_report',
        titleKey: 'completedLoading',
        dispatcherStatus: 'started',
        color: 'primary',
        priority: 2,
    },
    facility_arrival_reports: {
        id: 'facility_arrival_reports',
        reportType: 'fulfilment.facility_arrival_report',
        titleKey: 'arrivedAtFacility',
        dispatcherStatus: 'started',
        color: 'primary',
        priority: 3,
    },
    unloading_reports: {
        id: 'unloading_reports',
        reportType: 'fulfilment.unloading_report',
        titleKey: 'completedUnloading',
        dispatcherStatus: 'started',
        color: 'primary',
        priority: 4,
    },
    impossible_task_reports: {
        id: 'impossible_task_reports',
        reportType: 'fulfilment.impossible_task_report',
        titleKey: 'impossible',
        dispatcherStatus: 'error',
        color: 'danger',
        priority: 10,
    },
    completed_task_reports: {
        id: 'completed_task_reports',
        reportType: 'fulfilment.completed_task_report',
        titleKey: 'acceptedByFacility',
        dispatcherStatus: 'done',
        color: 'success',
        priority: 5,
    },
    incompleted_task_reports: {
        id: 'incompleted_task_reports',
        reportType: 'fulfilment.incompleted_task_report',
        titleKey: 'notAcceptedByFacility',
        dispatcherStatus: 'error',
        color: 'danger',
        priority: 5,
    },
    waste_tank_delivery_reports: {
        id: 'waste_tank_delivery_reports',
        reportType: 'fulfilment.waste_tank_delivery_report',
        titleKey: 'deliveredWastetank',
        dispatcherStatus: 'done',
        color: 'success',
        priority: 5,
    },
};

export const REPORT_TYPES = Object.values(CARRIER_REPORTS_PRIORITY)
    .map((v) => v.reportType)
    .filter((v) => !!v);

export const SHARE_REPLAY_SETTINGS = { bufferSize: 1, refCount: true };

export const PHONE_MASK = bazisConfig.PHONE_MASK;
export const PHONE_PATTERN = bazisConfig.PHONE_PATTERN;

export const VEHICLE_MASK_SETTINGS: Partial<MaskSettings> = bazisConfig.VEHICLE_MASK_SETTINGS;
export const PHONE_MASK_SETTINGS: Partial<MaskSettings> = bazisConfig.PHONE_MASK_SETTINGS;

export const PHONE_INPUT_TRANSFORM_FN = bazisConfig.PHONE_INPUT_TRANSFORM_FN;

export const COMMON_DATE_MASK_SETTINGS = bazisConfig.COMMON_DATE_MASK_SETTINGS;

export const SNILS_PATTERN = bazisConfig.SNILS_PATTERN;
export const SNILS_MASK_SETTINGS = bazisConfig.SNILS_MASK_SETTINGS;

export const BIC_PATTERN = bazisConfig.BIC_PATTERN;
export const RRC_PATTERN = bazisConfig.RRC_PATTERN;
export const INN_PATTERN = bazisConfig.INN_PATTERN;
export const BANK_ACCOUNT_PATTERN = bazisConfig.BANK_ACCOUNT_PATTERN;
export const CORRESPONDENT_ACCOUNT_PATTERN = bazisConfig.CORRESPONDENT_ACCOUNT_PATTERN;
export const OKTMO_MASK_SETTINGS = bazisConfig.OKTMO_MASK_SETTINGS;

export const FKKO_UNIVERSAL_FILTER = { class_fkko: [3, 4, 5] };
export const AVG_SPEED = 50;

export const FKKO_CLASS_OPTIONS_LIST = [
    {
        id: '3',
        nameKey: 'title.classNumber',
        nameParams: { number: 3 },
    },
    {
        id: '4',
        nameKey: 'title.classNumber',
        nameParams: { number: 4 },
    },
    {
        id: '5',
        nameKey: 'title.classNumber',
        nameParams: { number: 5 },
    },
];

export const MOBILE_ROLES = [ROLE.driver, ROLE.wasteAcceptor];

export const HEADER_MENU = {
    [ROLE.anonymous]: [
        {
            titleKey: 'header.lots',
            url: '/lots',
        },
        // {
        //     titleKey: 'header.myWasteplaces',
        //     url: '/wasteplaces',
        // },
        {
            titleKey: 'header.infoCenter',
            url: '/',
            children: [
                {
                    titleKey: 'header.agreement',
                    url: DOCUMENT_LINKS.userAgreement,
                },
                {
                    titleKey: 'header.faq',
                    url: '/faq',
                },
            ],
        },
    ],
    [ROLE.base]: [
        {
            titleKey: 'header.userInitial',
            url: '/user-initial',
        },
    ],
    [ROLE.driver]: [],
    [ROLE.wasteAcceptor]: [],
    [ROLE.director]: [
        {
            titleKey: 'header.companyUsers',
            url: '/director/users',
        },
        {
            titleKey: 'header.roleRequests',
            url: '/director/requests',
        },
    ],
    [ROLE.generalPartner]: [
        {
            titleKey: 'header.partnerOrganizations',
            url: '/organizations/partners',
        },
    ],
    [ROLE.referral]: [
        {
            titleKey: 'header.summary',
            url: '/referral/summary',
        },
        {
            titleKey: 'header.partners',
            url: '/referral/partners',
        },
        {
            titleKey: 'header.checkPartners',
            url: '/referral/check-partners',
        },
        {
            titleKey: 'header.grades',
            url: '/referral/grades',
        },
        // {
        //     titleKey: 'header.promo',
        //     url: '/referral/promo',
        // },
    ],
    [ROLE.wasteGenerator]: [
        {
            titleKey: 'header.addRequest',
            children: [
                {
                    titleKey: 'header.addRequestOneTime',
                    url: 'drafts/order-bid/new/',
                },
                {
                    titleKey: 'header.addRequestLongTime',
                    url: 'drafts/contract-bid/new/',
                },
                {
                    titleKey: 'header.addContractOrder',
                    url: 'drafts/contract-order-bid/new/',
                },
            ],
        },
        {
            titleKey: 'header.wasteplaces',
            url: '/wasteplaces',
        },
        {
            titleKey: 'header.contracts',
            url: '/contracts',
            // children: [],
        },
        {
            titleKey: 'header.orders',
            url: '/orders',
        },
        {
            titleKey: 'header.more',
            url: '/',
            type: 'more',
            children: [
                {
                    titleKey: 'header.myRequests',
                    url: '/bids',
                },
                {
                    titleKey: 'header.carrierTasks',
                    url: '/carrier-tasks',
                },
            ],
        },
    ],
    [ROLE.wasteRecycling]: [
        {
            titleKey: 'header.facilities',
            url: '/facilities',
        },
        {
            titleKey: 'header.facilityOffers',
            url: '/facility-offers',
        },
        {
            titleKey: 'header.contracts',
            url: '/contracts',
        },
        {
            titleKey: 'header.orders',
            url: '/orders',
        },
        {
            titleKey: 'header.carrierTasks',
            url: '/carrier-tasks',
        },
    ],
    [ROLE.wasteTransportation]: [
        {
            titleKey: 'header.lots',
            children: [
                {
                    titleKey: 'header.exportLots',
                    url: '/lots',
                },
                {
                    titleKey: 'header.saleCarrierLots',
                    url: '/sale-carrier-lots',
                },
            ],
        },
        {
            titleKey: 'header.myVehicles',
            url: '/vehicles',
            children: [
                {
                    titleKey: 'header.myVehicleList',
                    url: '/vehicles',
                },
                {
                    titleKey: 'header.setupTelemetry',
                    url: '/setup-telemetry',
                },
            ],
        },
        {
            titleKey: 'header.contracts',
            children: [
                {
                    titleKey: 'header.exportContracts',
                    url: '/contracts',
                },
                {
                    titleKey: 'header.saleContracts',
                    url: '/sale-contracts',
                },
            ],
        },
        {
            titleKey: 'header.orders',
            url: '/orders',
        },
        {
            titleKey: 'header.carrierTasks',
            url: '/carrier-tasks',
        },
        {
            titleKey: 'header.dispatcher',
            url: '/dispatcher',
        },
    ],

    [ROLE.seller]: [
        {
            titleKey: 'header.addRequest',
            url: '/drafts/sale-lot/new/',
        },
        {
            titleKey: 'header.lots',
            url: '/sale-lots',
        },
        {
            titleKey: 'header.contracts',
            url: '/sale-contracts',
        },
    ],
    [ROLE.buyer]: [
        {
            titleKey: 'header.lots',
            url: '/sale-lots',
        },
        {
            titleKey: 'header.contracts',
            url: '/sale-contracts',
        },
    ],
    [ROLE.wasteOperator]: [
        {
            titleKey: 'header.organizations',
            url: '/organizations',
        },

        {
            titleKey: 'header.facilityOffers',
            url: '/facility-offers',
        },
        {
            titleKey: 'header.requests',
            url: '/bids',
        },
        {
            titleKey: 'header.contracts',
            children: [
                {
                    titleKey: 'header.exportContracts2',
                    url: '/contracts',
                },
                {
                    titleKey: 'header.saleContracts2',
                    url: '/sale-contracts',
                },
            ],
        },
        {
            titleKey: 'header.orders',
            url: '/orders',
        },
        {
            titleKey: 'header.lots',
            children: [
                {
                    titleKey: 'header.exportLots',
                    url: '/lots',
                },
                {
                    titleKey: 'header.saleLots',
                    url: '/sale-lots',
                },
                {
                    titleKey: 'header.saleCarrierLots',
                    url: '/sale-carrier-lots',
                },
            ],
        },
        {
            titleKey: 'header.more',
            url: '/',
            type: 'more',
            children: [
                {
                    titleKey: 'header.wasteplaces',
                    url: '/wasteplaces',
                },
                {
                    titleKey: 'header.vehicles',
                    url: '/vehicles',
                },
                {
                    titleKey: 'header.facilities',
                    url: '/facilities',
                },
                {
                    titleKey: 'header.dispatcher',
                    url: '/dispatcher',
                },
                {
                    titleKey: 'header.operatorReports',
                    url: '/operator-reports',
                    queryParams: { status: 'draft' },
                },
                {
                    titleKey: 'header.ossigPermissions',
                    url: '/ossig-permissions',
                },
                {
                    titleKey: 'header.roleRequests',
                    url: '/director/requests',
                },
                {
                    titleKey: 'header.summary',
                    url: '/summary',
                },
                {
                    titleKey: 'header.analytics',
                    url: '/analytics',
                },
            ],
        },
    ],
    [ROLE.empty]: [{}],
};

export const USER_MENU = [
    {
        titleKey: 'header.personalInfo',
        url: '/profile/personal-info',
        icon: 'portrait',
        excludeForRoles: [],
    },
    {
        titleKey: 'header.account',
        url: '/profile/account',
        icon: 'credit-card',
        excludeForRoles: [ROLE.base, ROLE.director, ...MOBILE_ROLES],
    },
    {
        titleKey: 'header.notifications',
        url: '/profile/notifications',
        icon: 'bell',
        excludeForRoles: [ROLE.base, ROLE.director],
    },
    {
        type: 'roles',
        titleKey: 'header.enterAs',
        icon: 'arrow-right',
        excludeForRoles: [],
    },
    {
        titleKey: 'header.logout',
        url: '/logout',
        icon: 'power',
        excludeForRoles: [],
    },
];

export const USER_STATUSES = {
    active: {
        color: 'success',
        nameKey: 'director.users.status.active',
    },
    waiting: {
        color: 'secondary',
        nameKey: 'director.users.status.waiting',
    },
    blocked: {
        color: 'error',
        nameKey: 'director.users.status.blocked',
    },
};

export const FOOTER_MENU = {
    [ROLE.wasteGenerator]: [
        {
            titleKey: 'footer.addRequest',
            url: '/drafts/bid/new',
        },
        {
            titleKey: 'footer.requests',
            url: '/bids',
        },
        {
            titleKey: 'footer.account',
            url: '/profile/account',
        },
        {
            titleKey: 'footer.profileSettings',
            url: '/profile/settings',
        },
    ],
    [ROLE.wasteRecycling]: [
        {
            titleKey: 'footer.account',
            url: '/profile/account',
        },
        {
            titleKey: 'footer.profileSettings',
            url: '/profile/settings',
        },
    ],
    [ROLE.wasteTransportation]: [
        {
            titleKey: 'footer.account',
            url: '/profile/account',
        },
        {
            titleKey: 'footer.profileSettings',
            url: '/profile/settings',
        },
    ],
    [ROLE.wasteOperator]: [
        {
            titleKey: 'footer.account',
            url: '/profile/account',
        },
        {
            titleKey: 'footer.profileSettings',
            url: '/profile/settings',
        },
    ],
    [ROLE.generalPartner]: [
        {
            titleKey: 'footer.account',
            url: '/profile/account',
        },
        {
            titleKey: 'footer.profileSettings',
            url: '/profile/settings',
        },
    ],
    [ROLE.seller]: [
        {
            titleKey: 'footer.addRequest',
            url: '/drafts/sale-lot/new',
        },
        {
            titleKey: 'footer.lots',
            url: '/sale-lots',
        },
        {
            titleKey: 'footer.account',
            url: '/profile/account',
        },
        {
            titleKey: 'footer.profileSettings',
            url: '/profile/settings',
        },
    ],
    [ROLE.buyer]: [
        {
            titleKey: 'footer.account',
            url: '/profile/account',
        },
        {
            titleKey: 'footer.profileSettings',
            url: '/profile/settings',
        },
    ],
    [ROLE.base]: [
        {
            titleKey: 'footer.personalInfo',
            url: '/profile/personal-info',
        },
        {
            titleKey: 'footer.profileSettings',
            url: '/profile/settings',
        },
    ],
    [ROLE.director]: [
        {
            titleKey: 'footer.personalInfo',
            url: '/profile/personal-info',
        },
        {
            titleKey: 'footer.profileSettings',
            url: '/profile/settings',
        },
    ],
};

export const DEFAULT_LINK_ACTIVE_MATCH_OPTIONS: IsActiveMatchOptions =
    bazisConfig.DEFAULT_LINK_ACTIVE_MATCH_OPTIONS;

export const SW_ORG_TIN = '7707459899';

@Injectable({
    providedIn: 'root',
})
export class ConfigurationService extends BazisConfigurationService {
    protected configuration: any = {
        tiles: {
            default: {
                url: 'https://{s}.map.asu.big3.ru/street/{z}/{x}/{y}.png',
                size: 256,
            },
            grey: {
                url: 'https://api.mapbox.com/styles/v1/smart-waste/cl7t82ptn006315lg5yiys80b/tiles/256/{z}/{x}/{y}?&access_token=pk.eyJ1Ijoic21hcnQtd2FzdGUiLCJhIjoiY2w3dDdyYWtvMDFzcjNucWxkaDR6aXk3dSJ9.DwsYQbL40ED-guYA9sE-0g',
                size: 256,
            },
        },
        emptyInputValue: '-',
        defaultLocation: [55.75222, 37.61556],
        contacts: { email: 'info@smartwaste.ru', phone: '+7 495 822-22-33' },
        vehicleNumberMask: ['V', '000', 'VV', '000'],
    };

    protected settingsUrl = 'smart_waste/settings';

    selectedLanguage = SELECTED_LANGUAGE;

    availableLanguages = AVAILABLE_LANGUAGES;

    get buyerFeePercent(): any {
        return this.configuration?.SALES_FEE_PERCENT || 0;
    }

    get isNominalAccountAvailable() {
        return this.configuration?.USE_NOMINAL_ACC;
        //return false;
    }

    get signatureType(): 'crypto' | 'docusign' {
        return 'crypto';
    }

    get appAndroidLink(): any {
        return this.configuration?.SW_APP_ANDROID_URL || '';
    }

    get appIOsLink(): any {
        return this.configuration?.SW_APP_IOS_URL || '';
    }
}
